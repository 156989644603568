import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的问题列表
export function GetQuestionsTypeAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/questions/types/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取问题的分页列表
export function GetQuestionsTypeList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/questions/types/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的问题数据
export function GetQuestionsTypeById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/questions/types/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定问题的数据
export function PutQuestionsTypeById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/questions/types/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建问题
export function PostQuestionsType(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/clients/questions/types`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 批量新增
export function MultiplePostQuestionsType(params = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        params.forEach(item => {
            multipleRequests.push(apiv1.post(`/clients/questions/types`, item))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })

}

// 批量删除
export function MultipleDeleteQuestionsTypes(multipleSelectieIds = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(id => {
            multipleRequests.push(apiv1.delete(`/clients/questions/types/${id}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}


// 检查是否可以更新
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/questions/types/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以禁用/启用
export function GetDeleteCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/questions/types/${id}/can/delete`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}